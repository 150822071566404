import { faCommentLines, faWarning } from "@fortawesome/pro-duotone-svg-icons";
import {
    faFaceSleeping,
    faTag,
    faTags,
} from "@fortawesome/pro-regular-svg-icons";
import { DraggableProvided } from "@hello-pangea/dnd";
import { isAfter, parse } from "date-fns";
import { AnimatePresence } from "framer-motion";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Badge from "../../../components/UI/Badge";
import Icon from "../../../components/UI/Icon";
import Spinner from "../../../components/UI/Spinner";
import StopCardIcon from "../../../components/UI/StopCardIcon";
import { StopDraft } from "../../../shared/types/api";
import { formatEta } from "../../../shared/utility/date";
import { convertToNumberWithSpaces } from "../../../shared/utility/misc";
import {
    getStopLocationText,
    getStopStatus,
} from "../../../shared/utility/stop-draft";
import {
    STOP_DRAFT_TYPE,
    STOP_STATUS_COLOR,
    STOP_STATUS_TEXT,
} from "../../../shared/values/enums";
import AddPauseCard from "../AddPauseCard";
import "./style.scss";

export type StopCardProps = {
    // Drag and Drop
    provided?: DraggableProvided;

    //Core data
    stop: StopDraft;
    index: number;
    runningWeight?: number;
    eta?: string;
    groupedWeight?: number;
    order?: number;

    //states
    isLoading?: boolean;
    color?: string;
    groupedAmount?: number;
    hideBecauseOfGroup?: boolean;

    //actions handlers
    onHover?: (stop: StopDraft | null) => void;
    onClick?: () => void;
    onAddPause?: (data: {
        toLocation?: string;
        estimatedOffloadTime: number;
        tourPosition: number;
        driverInstructions?: string;
    }) => void;
};

const warningIconStyle = {
    "--fa-primary-color": "var(--color-neutral-900)",
    "--fa-primary-opacity": "1",
    "--fa-secondary-color": "var(--color-yellow-400)",
    "--fa-secondary-opacity": "1",
};

const commentIconStyle = {
    "--fa-primary-color": "var(--color-pure-white)",
    "--fa-primary-opacity": "1",
    "--fa-secondary-color": "var(--color-sky-400)",
    "--fa-secondary-opacity": "1",
};

function StopCard(props: StopCardProps) {
    const { t } = useTranslation();

    const [isAddingPause, setIsAddingPause] = useState(false);

    const { isCompleted, statusColor, statusText } = useMemo(() => {
        const stopStatus = getStopStatus(props.stop);
        return {
            isCompleted: stopStatus === "completed",
            statusColor: STOP_STATUS_COLOR[stopStatus],
            statusText: t(STOP_STATUS_TEXT[stopStatus]),
        };
    }, [props.stop, t]);

    const stopCardClasses = ["stop-card"];
    if (isCompleted) {
        stopCardClasses.push("completed");
    }

    const etaString = useMemo(() => {
        const eta = formatEta(
            props.stop.completed_at ||
                props.stop.arrived_at ||
                props.stop.eta_internal ||
                undefined,
            {
                showTimeIfInThePast: true,
            }
        );

        return eta || props.eta;
    }, [
        props.eta,
        props.stop.arrived_at,
        props.stop.completed_at,
        props.stop.eta_internal,
    ]);

    const isLate = useMemo(() => {
        if (!props.stop.time_tooltip || !etaString) return false;

        const parsedTooltip = parse(
            props.stop.time_tooltip,
            "HH:mm",
            new Date()
        );
        const parsedEta = parse(etaString, "HH:mm", new Date());

        return isAfter(parsedEta, parsedTooltip);
    }, [props.stop.time_tooltip, etaString]);

    const weightString = useMemo(() => {
        const weight = props.groupedWeight || props.stop.weight_kg || 0;

        if (props.runningWeight === undefined)
            return convertToNumberWithSpaces(weight, "kg");

        return `${
            props.stop.stop_type_id === STOP_DRAFT_TYPE.Pickup ? "+" : "-"
        }${weight} kg (${props.runningWeight} kg)`;
    }, [
        props.groupedWeight,
        props.runningWeight,
        props.stop.stop_type_id,
        props.stop.weight_kg,
    ]);

    const showAddPause = useMemo(() => {
        return props.onAddPause && !props.hideBecauseOfGroup && !isAddingPause;
    }, [isAddingPause, props.hideBecauseOfGroup, props.onAddPause]);

    return (
        <div
            className="stop-card-wrapper"
            ref={props.provided?.innerRef}
            {...props.provided?.draggableProps}
            {...props.provided?.dragHandleProps}
        >
            <div
                className={stopCardClasses.join(" ")}
                onMouseEnter={() => props.onHover?.(props.stop)}
                onMouseLeave={() => props.onHover?.(null)}
                data-group-id={props.stop.group_id}
                data-card-id={props.stop.id}
                onClick={props.onClick}
                style={{
                    boxShadow: isCompleted ? "none" : "var(--shadow)",
                    display: props.hideBecauseOfGroup ? "none" : undefined,
                    marginBottom: !showAddPause ? 6 : undefined,
                }}
            >
                {props.isLoading && (
                    <div className="loading-wrapper">
                        <Spinner />
                    </div>
                )}
                <div className="top">
                    <div className="header">
                        <StopCardIcon
                            tooltipData={{
                                address: props.stop.street_address || "",
                                status: props.stop.tour_id
                                    ? statusText
                                    : undefined,
                                cargoContent: props.stop.cargo_content,
                                comment: props.stop.driver_note
                                    ? `${t("fleetPlanner.driverNote")}: ${
                                          props.stop.driver_note
                                      }`
                                    : undefined,
                                iconColor: props.stop.tour_id
                                    ? statusColor.color
                                    : undefined,
                                alignment: {
                                    y: props.index < 2 ? "bottom" : "top",
                                    x: "left",
                                },
                            }}
                            iconColor={
                                props.stop.tour_id
                                    ? statusColor.color
                                    : undefined
                            }
                            isCompleted={isCompleted}
                            label={
                                props.order?.toString() ||
                                (props.index + 1).toString()
                            }
                            color={
                                isCompleted
                                    ? "var(--color-neutral-200)"
                                    : props.color
                            }
                            groupedAmount={props.groupedAmount}
                        />
                        <div className="header-info">
                            <p className="type text-2xs">
                                {props.stop.stop_type_id ===
                                STOP_DRAFT_TYPE.Pickup
                                    ? t("bigVolume.loading")
                                    : t("bigVolume.unloading")}
                            </p>
                            <p
                                className="address text-xs"
                                title={props.stop.to_location}
                            >
                                {getStopLocationText(props.stop)}
                            </p>
                        </div>
                    </div>
                    <div className="eta-wrapper">
                        {props.stop.driver_note && (
                            <div className="is-late-wrapper">
                                <Icon
                                    icon={faCommentLines}
                                    style={
                                        commentIconStyle as React.CSSProperties
                                    }
                                    size="lg"
                                    tooltipData={{
                                        alignment: {
                                            x: "right",
                                            y: "bottom",
                                        },
                                        description: props.stop.driver_note,
                                        descriptionMaxWidth: "250px",
                                    }}
                                />
                            </div>
                        )}

                        {props.stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
                            isLate && (
                                <div className="is-late-wrapper">
                                    <Icon
                                        icon={faWarning}
                                        style={
                                            warningIconStyle as React.CSSProperties
                                        }
                                        size="lg"
                                        tooltipData={{
                                            alignment: {
                                                x: "right",
                                                y: "top",
                                            },
                                            description: t(
                                                "bigVolume.isLateTitle"
                                            ),
                                        }}
                                    />
                                </div>
                            )}

                        <p className="eta">{etaString || "-"}</p>
                    </div>
                </div>
                <div className="info">
                    {weightString ? (
                        <p className="text-xs">{weightString}</p>
                    ) : (
                        <div />
                    )}
                    {props.stop.tags ? (
                        <div className="badge-wrapper">
                            {props.stop.tags.length > 1 ? (
                                <Badge
                                    title={`+${props.stop.tags.length} ${t(
                                        "bigVolume.cardTags"
                                    )}`}
                                    variant="neutral"
                                    icon={faTags}
                                    tooltipData={{
                                        description: props.stop.tags.join("\n"),
                                    }}
                                />
                            ) : props.stop.tags.length === 1 ? (
                                <div style={{ maxWidth: "100px" }}>
                                    <Badge
                                        title={props.stop.tags[0]}
                                        variant="neutral"
                                        icon={faTag}
                                        hasTitleTooltip
                                    />
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
            {showAddPause && (
                <div
                    className="add-pause"
                    onClick={() => setIsAddingPause(true)}
                >
                    <div className="inner">
                        <Icon
                            icon={faFaceSleeping}
                            size="lg"
                            color="var(--color-neutral-400)"
                        />
                        <p style={{ color: "var(--color-neutral-600)" }}>
                            {t("bigVolume.addPause")}
                        </p>
                    </div>
                </div>
            )}
            <AnimatePresence>
                {isAddingPause && (
                    <AddPauseCard
                        onAddPause={(data) => {
                            const pausePosition =
                                (props.groupedAmount
                                    ? props.groupedAmount - 1
                                    : 0) +
                                props.index +
                                1;

                            props.onAddPause?.({
                                ...data,
                                tourPosition: pausePosition,
                            });
                            setIsAddingPause(false);
                        }}
                        onClose={() => setIsAddingPause(false)}
                    />
                )}
            </AnimatePresence>
        </div>
    );
}

export default StopCard;
