import {
    faColumns,
    faFileCirclePlus,
    faGrid2,
    faRefresh,
    faSearch,
    faSquare,
    faVideoCircle,
    faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { DropResult, OnDragEndResponder } from "@hello-pangea/dnd";
import { useQueryClient } from "@tanstack/react-query";
import { isSameDay } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    acceptStopDraftLocationChange,
    fulfillStopDraft,
    undoStopDraftLocationChange,
    updateOrderDraft,
} from "../../api/stop-draft";
import {
    addPause,
    archiveStopDraftTour,
    consolidateTour,
    removeStopFromTour,
} from "../../api/tours";
import BigVolumeSettingsModal from "../../big-volume/modals/BigVolumeSettingsModal";
import CockpitStopColumnsModal from "../../big-volume/modals/CockpitStopColumnsModal";
import StopTable from "../../big-volume/tables/StopTable";
import TourTable from "../../big-volume/tables/TourTable";
import Button from "../../components/buttons/Button";
import Tabs from "../../components/buttons/Tabs";
import Input from "../../components/inputs/Input";
import WeekDaySelector from "../../components/inputs/WeekDaySelector";
import Badge from "../../components/UI/Badge";
import DotsModal from "../../components/UI/DotsModal";
import alertPrompt from "../../components/widgets/alertPrompt";
import StopFilterModal from "../../containers/StopFilterModal";
import ToursDragDrop from "../../fleet-planner/ToursDragDrop";
import useFleetPlannerData from "../../hooks/data/useFleetPlannerData";
import useBulkDeletion from "../../hooks/functionality/useBulkDeletion";
import useDemoMutations from "../../hooks/functionality/useDemoMutations";
import useFleetPlannerDrag from "../../hooks/functionality/useFleetPlannerDrag";
import useStopTableAndTourTableFilterAndSort from "../../hooks/functionality/useStopAndTourFilterAndSort";
import useUserFeatures from "../../hooks/functionality/useUserFeatures";
import { useFleetRoute } from "../../hooks/route/useFleetRoute";
import BigVolumeMap from "../../maps/BigVolumeMap";
import ExternalStopsPopup from "../../popups/ExternalStopsPopup";
import GroupTourStopsPopup from "../../popups/GroupTourStopsPopup";
import StopChangeLocationPopup from "../../popups/StopChangeLocationPopup";
import StopFormPopup from "../../popups/StopFormPopup";
import StopsInfoPopup from "../../popups/StopsInfoPopup";
import analytics from "../../shared/services/ga4";
import {
    OrderDraft,
    Pause,
    StopDraft,
    StopDraftsTour,
    TourStop,
} from "../../shared/types/api";
import {
    DriverStatus,
    KeyString,
    TabOption,
} from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { dateToString } from "../../shared/utility/date";
import { isEnvironment } from "../../shared/utility/misc";
import {
    getCombinedStops,
    getDriverStatus,
} from "../../shared/utility/stop-draft";
import { getNextStop } from "../../shared/utility/tour";
import { NEW_TOUR_COLUMN_ID, STOP_DRAFT_TYPE } from "../../shared/values/enums";
import { tourColors } from "../../shared/values/lists";
import { setFleetPlannerSettings } from "../../store/slices/fleetPlanner";
import "./style.scss";

export type StopTableData = {
    stops?: { pickup: StopDraft; dropoff: StopDraft };
    orderDraft?: OrderDraft;
};

export type TourTableData = {
    tour?: StopDraftsTour;
    stops?: {
        pickup: StopDraft;
        dropoff: StopDraft;
    };
};

const MAX_MARK_ALL_STOPS = 100;

type Props = {};

function BigVolume(props: Props) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { filterDate, setFilterDate } = useFleetRoute();

    const { settings } = useSelector((state: ReduxState) => state.fleetPlanner);
    const { user } = useSelector((state: ReduxState) => state.auth);
    const features = useUserFeatures();
    const dispatch = useDispatch();

    const {
        // Data
        tours,
        stopDrafts,
        orderDrafts,
        drivers,
        movedStops,

        // Statuses
        isFetchingStopDrafts,
        isFetchingTours,

        reloadData,
    } = useFleetPlannerData({
        filterDate: filterDate ? dateToString(filterDate) : undefined,
    });

    const {
        onDragEndStopDraftInTour,
        columnIdsLoading,
        unhandledIdsLoading,
        sortTourPickupsFirst,
    } = useFleetPlannerDrag({
        filterDate: dateToString(filterDate),
        tours,
        stopDrafts,
        reloadData,
    });

    const {
        sortStopTable,
        sortTourTable,

        filterStopTable,
        filterTourTable,

        activeStopFilter,
        setActiveStopFilter,
        activeStopSort,
        setActiveStopSort,
        stopSearch,
        setStopSearch,

        tourSearch,
        setTourSearch,
        activeTourSort,
        setActiveTourSort,
    } = useStopTableAndTourTableFilterAndSort();

    const {
        createDemoStopDrafts,
        isCreatingDemoStopDrafts,
        deleteAllStopDrafts,
        isCreatingTestStopDrafts,
        createTestStopDrafts,
        isDeletingAllStopDrafts,
        archiveAllActiveTours,
        isArchivingAllActiveTours,
        createDemoBigVolumeStopDrafts,
        isCreatingDemoBigVolumeStopDrafts,
        createInteBaraPostenDemoData,
        isCreatingInteBaraPostenDemoData,
    } = useDemoMutations({
        onSuccess: async () => reloadData("tours", "stop-drafts"),
    });

    const {
        bulkDeleteOrderDrafts,
        bulkDeleteStops,
        isBulkDeletingStops,
        isBulkDeletingOrderDrafts,
    } = useBulkDeletion({
        onSuccess: async () => reloadData("stop-drafts", "order-drafts"),
    });

    const [selectedStops, setSelectedStops] = useState<TourStop[]>([]);
    const [selectedTourIds, setSelectedTourIds] = useState<number[]>([]);
    const [isMarkingAll, setIsMarkingAll] = useState(false);
    const [hoveredStopId, setHoveredStopId] = useState<number | null>(null);

    const [onlyShowUnresolved, setOnlyShowUnresolved] = useState(false);

    const [sectionsSize, setSectionsSize] = useState<
        "quarter" | "half" | "full"
    >("quarter");

    const sectionSizes: TabOption[] = useMemo(() => {
        return [
            {
                value: "quarter",
                label: "",
                icon: faGrid2,
            },
            {
                value: "half",
                label: "",
                icon: faColumns,
            },
            {
                value: "full",
                label: "",
                icon: faSquare,
            },
        ];
    }, []);

    //popups
    const [isStopPopupOpen, setIsStopPopupOpen] = useState(false);

    const [editStops, setEditStops] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [copyStops, setCopyStops] = useState<
        | {
              pickup: StopDraft;
              dropoff: StopDraft;
          }
        | undefined
    >();
    const [stopPopupData, setStopPopupData] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
        groupedStops?: {
            pickup: StopDraft;
            dropoff: StopDraft;
        }[];
    } | null>(null);
    const [orderDraftForCreate, setOrderDraftForCreate] =
        useState<OrderDraft | null>(null);
    const [changeLocationStops, setChangeLocationStops] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [stopsForExternalMarking, setStopsForExternalMarking] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);
    const [groupTourId, setGroupTourId] = useState<number | null>(null);
    const [
        showGroupPopupWithSelectedStops,
        setShowGroupPopupWithSelectedStops,
    ] = useState(false);

    //loading
    const [detachingIds, setDetachingIds] = useState<number[]>([]);

    useEffect(() => {
        if (!filterDate) return;
        //If a user changes location, we need to reset the selected stops and stuff
        if (!user?.location_id) return;

        setSelectedStops([]);
        setSelectedTourIds([]);
        setIsMarkingAll(false);
    }, [filterDate, user?.location_id]);

    // START FILTER AND SORT
    const unhandledStops = useMemo(() => {
        if (!stopDrafts) return [];

        const stops = stopDrafts.filter((stop) => {
            if (
                settings.filterUnhandledByDate &&
                filterDate &&
                stop.date_tooltip
            ) {
                if (stop.date_tooltip !== dateToString(filterDate)) {
                    return false;
                }
            }

            const isExternal = !!stop.external;
            if (isExternal) {
                return false;
            }

            const isAssignedFromOtherLocation = stop.origin_location;
            const isStopAccepted = stop.move_accepted_at;
            if (isAssignedFromOtherLocation && !isStopAccepted) {
                return false;
            }

            return true;
        });

        return getCombinedStops(stops);
    }, [filterDate, settings.filterUnhandledByDate, stopDrafts]);

    const unacceptedStopsFromOtherLocations = useMemo(() => {
        if (!stopDrafts) return [];

        const stops = stopDrafts.filter((stop) => {
            if (
                settings.filterUnhandledByDate &&
                filterDate &&
                stop.date_tooltip
            ) {
                if (stop.date_tooltip !== dateToString(filterDate)) {
                    return false;
                }
            }

            const isExternal = !!stop.external;
            if (isExternal) {
                return false;
            }

            const isAssignedFromOtherLocation = stop.origin_location;
            const isStopAccepted = stop.move_accepted_at;
            if (isAssignedFromOtherLocation && !isStopAccepted) {
                return true;
            }

            return false;
        });

        return getCombinedStops(stops);
    }, [filterDate, settings.filterUnhandledByDate, stopDrafts]);

    const stopTableData = useMemo<StopTableData[]>(() => {
        const data: StopTableData[] = [];

        for (let i = 0; i < unacceptedStopsFromOtherLocations.length; i++) {
            const stops = unacceptedStopsFromOtherLocations[i];

            data.push({
                stops,
            });
        }

        if (!onlyShowUnresolved) {
            for (let i = 0; i < unhandledStops.length; i++) {
                const stops = unhandledStops[i];

                data.push({
                    stops,
                });
            }
        }

        if (orderDrafts) {
            for (let i = 0; i < orderDrafts.length; i++) {
                const orderDraft = orderDrafts[i];

                data.push({
                    orderDraft,
                });
            }
        }

        return data;
    }, [
        onlyShowUnresolved,
        orderDrafts,
        unacceptedStopsFromOtherLocations,
        unhandledStops,
    ]);

    const filteredAndStortedStopTableData = useMemo(() => {
        let result = stopTableData.filter(filterStopTable).sort(sortStopTable);

        if (onlyShowUnresolved) {
            result = result.filter(
                ({ orderDraft, stops }) =>
                    !!orderDraft ||
                    (stops?.pickup.origin_location_id &&
                        !stops.pickup.move_accepted_at)
            );
        }

        return result;
    }, [filterStopTable, onlyShowUnresolved, sortStopTable, stopTableData]);

    const stopsSentToOtherLocations = useMemo(() => {
        if (!movedStops) return [];

        const stops = movedStops.filter((stop) => {
            if (
                settings.filterUnhandledByDate &&
                filterDate &&
                stop.date_tooltip
            ) {
                if (stop.date_tooltip !== dateToString(filterDate)) {
                    return false;
                }
            }

            return true;
        });

        return getCombinedStops(stops);
    }, [filterDate, movedStops, settings.filterUnhandledByDate]);

    const externalStops = useMemo(() => {
        if (!stopDrafts) return [];

        const stops = stopDrafts.filter((stop) => {
            if (
                settings.filterUnhandledByDate &&
                filterDate &&
                stop.date_tooltip
            ) {
                if (stop.date_tooltip !== dateToString(filterDate)) {
                    return false;
                }
            }

            const isExternal = !!stop.external;
            if (isExternal) {
                return true;
            }

            return false;
        });

        return getCombinedStops(stops);
    }, [filterDate, settings.filterUnhandledByDate, stopDrafts]);

    const tourTableData = useMemo<TourTableData[]>(() => {
        if (!tours) return [];

        const data: TourTableData[] = [];

        for (let i = 0; i < tours.length; i++) {
            const tour = tours[i];

            data.push({
                tour,
            });
        }

        for (let i = 0; i < stopsSentToOtherLocations.length; i++) {
            const stops = stopsSentToOtherLocations[i];
            data.push({
                stops,
            });
        }

        for (let i = 0; i < externalStops.length; i++) {
            const stops = externalStops[i];
            data.push({
                stops,
            });
        }

        return data;
    }, [externalStops, stopsSentToOtherLocations, tours]);

    const sortedTourTableData = useMemo(() => {
        if (!tourTableData) return [];

        return tourTableData.filter(filterTourTable).sort(sortTourTable);
    }, [filterTourTable, sortTourTable, tourTableData]);

    const selectedTours = useMemo(
        () => tours?.filter((t) => selectedTourIds.includes(t.tour_id)) || [],
        [selectedTourIds, tours]
    );
    // END FILTER AND SORT

    const groupTour = useMemo(() => {
        if (!tours) return null;

        return tours.find((tour) => tour.tour_id === groupTourId) || null;
    }, [groupTourId, tours]);

    const onReorderNewTour = useCallback(
        (result: DropResult) => {
            if (!result.destination) return;
            const { source, destination } = result;

            setSelectedStops((state) => {
                const newSelectedStops = [...state];

                const [removed] = newSelectedStops.splice(source.index, 1);
                newSelectedStops.splice(destination.index, 0, removed);

                const groupedStopIndex = newSelectedStops.findIndex(
                    (sd) =>
                        sd.group_id === removed?.group_id &&
                        sd.id !== removed.id
                );

                if (
                    removed.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                    destination.index > groupedStopIndex
                ) {
                    toast.error(t("errorMessage.invalidMovePickup"));
                    return state;
                } else if (
                    removed.stop_type_id === STOP_DRAFT_TYPE.Dropoff &&
                    destination.index < groupedStopIndex
                ) {
                    toast.error(t("errorMessage.invalidMoveDropoff"));
                    return state;
                }

                return newSelectedStops;
            });
        },
        [t]
    );

    const onDragEnd = useCallback<OnDragEndResponder>(
        async (result, provided) => {
            if (!result.destination) return;
            const { source, destination, draggableId } = result;

            //return if the card is dropped in the same place it was picked up
            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            )
                return;

            if (
                source.droppableId === NEW_TOUR_COLUMN_ID &&
                destination.droppableId === NEW_TOUR_COLUMN_ID
            ) {
                onReorderNewTour(result);
                return;
            }

            if (
                destination.droppableId === NEW_TOUR_COLUMN_ID &&
                source.droppableId !== NEW_TOUR_COLUMN_ID
            ) {
                toast.error(
                    t("errorMessage.cantMoveStopsToNewTourNeedsDetachment")
                );
                return;
            }

            await onDragEndStopDraftInTour({
                draggableId,
                source,
                destination,
                isFromUnhandledColumn:
                    source.droppableId === NEW_TOUR_COLUMN_ID,
                isDroppedInEmptyColumn: false,
                createdFrom: "big-volume",
                allowMoveGroupedStops: true,
                dontAllowGroupedStopsChangeColumn: true,
            });
        },
        [onDragEndStopDraftInTour, onReorderNewTour, t]
    );

    const detachStopsHandler = useCallback(
        async ({
            pickup,
            dropoff,
        }: {
            pickup: StopDraft;
            dropoff: StopDraft;
        }) => {
            const tour = tours?.find((t) => t.tour_id === pickup.tour_id);

            if (!tour) return;

            let stopToDetach = pickup;
            if (!!stopToDetach.completed_at) {
                stopToDetach = dropoff;
            }

            setDetachingIds((state) => [...state, stopToDetach.id]);

            try {
                await removeStopFromTour(stopToDetach.id);

                toast.success(t("activeFleet.stopDetached"));

                await reloadData("tours", "stop-drafts");
            } catch (error) {
                toast.error(t("errorMessage.stopDetachedError"));
            } finally {
                setDetachingIds((state) =>
                    state.filter((id) => id !== stopToDetach.id)
                );
            }
        },
        [reloadData, t, tours]
    );

    const detachPauseHandler = useCallback(
        async (pause: Pause) => {
            if (
                !(await alertPrompt({
                    title: t("activeFleet.confirmDetachPauseTitle"),
                    message: t("activeFleet.confirmDetachPauseMessage"),
                }))
            )
                return;

            const loadingToast = toast.loading(t("activeFleet.detachingPause"));

            try {
                await removeStopFromTour(pause.id);
                await reloadData("tours", "stop-drafts");
                toast.dismiss(loadingToast);
                toast.success(t("activeFleet.pauseDetached"));
            } catch (error) {
                toast.error(t("errorMessage.pauseDetachedError"));
            } finally {
                toast.dismiss(loadingToast);
            }
        },
        [reloadData, t]
    );

    const detachExternalStopHandler = useCallback(
        async (stop: StopDraft) => {
            try {
                await removeStopFromTour(stop.id);

                toast.success(t("activeFleet.stopDetached"));

                await reloadData("stop-drafts");
            } catch (error) {
                toast.error(t("errorMessage.stopDetachedError"));
            }
        },
        [reloadData, t]
    );

    const fulfillExternalStopHandler = useCallback(
        async (stopDraft: StopDraft) => {
            try {
                await fulfillStopDraft(stopDraft.id);
                queryClient.removeQueries({
                    queryKey: ["fulfilled-fleet"],
                    type: "all",
                });
                await reloadData("stop-drafts");
                toast.success(t("activeFleet.stopFulfilled"));
            } catch (error) {
                toast.error(t("errorMessage.stopFulfilledError"));
            }
        },
        [queryClient, reloadData, t]
    );

    const respondToStopRequestHandler = useCallback(
        async ({
            pickup,
            dropoff,
            response,
        }: {
            pickup: StopDraft;
            dropoff: StopDraft;
            response: "accept" | "reject";
        }) => {
            if (response === "accept") {
                try {
                    await acceptStopDraftLocationChange([
                        pickup.id,
                        dropoff.id,
                    ]);
                    toast.success(t("activeFleet.stopAccepted"));
                    await reloadData("stop-drafts", "moved-stops");
                } catch (error) {
                    toast.error(t("errorMessage.unknown"));
                }
                return;
            }

            if (response === "reject") {
                try {
                    await undoStopDraftLocationChange([pickup.id, dropoff.id]);

                    toast.success(t("activeFleet.stopSentBack"));
                    await reloadData("stop-drafts", "moved-stops");
                } catch (error) {
                    toast.error(t("errorMessage.unknown"));
                }
            }
        },
        [reloadData, t]
    );

    const onStopsCreated = useCallback(
        async (createdIds: number[]) => {
            setIsStopPopupOpen(false);

            if (orderDraftForCreate) {
                try {
                    await updateOrderDraft({
                        id: orderDraftForCreate.id,
                        stopDraftIds: createdIds,
                    });
                } catch (error) {
                    toast.error(
                        t(
                            "errorMessage.orderDraftUpdatedStopDraftsCreatedError"
                        )
                    );
                } finally {
                    setOrderDraftForCreate(null);
                }
            }

            reloadData("stop-drafts", "tours", "order-drafts");
        },
        [orderDraftForCreate, reloadData, t]
    );

    const bulkDeleteHandler = useCallback(
        async (type: "stop" | "order-draft") => {
            const accepted = await alertPrompt({
                title:
                    type === "stop"
                        ? t("activeFleet.deleteAllStopDraftsTitle")
                        : t("activeFleet.deleteAllOrderDraftsTitle"),
                message:
                    type === "stop"
                        ? t("activeFleet.deleteAllStopDraftsMessage")
                        : t("activeFleet.deleteAllOrderDraftsMessage"),
                confirmText: "OK",
            });

            if (!accepted) return;

            if (type === "stop") {
                bulkDeleteStops();
            } else if (type === "order-draft") {
                bulkDeleteOrderDrafts();
            }
        },
        [bulkDeleteOrderDrafts, bulkDeleteStops, t]
    );

    const archiveTourHandler = useCallback(
        async (tourId: number) => {
            if (
                !(await alertPrompt({
                    title: t("activeFleet.confirmArchiveTourTitle"),
                    message: t("activeFleet.confirmArchiveTour"),
                }))
            ) {
                return;
            }

            try {
                await archiveStopDraftTour(tourId);

                queryClient.removeQueries({
                    queryKey: ["fulfilled-fleet"],
                    type: "all",
                });

                toast.success(t("activeFleet.tourArchived"));
            } catch (error) {
                toast.error(t("errorMessage.tourArchivedError"));
            } finally {
                await reloadData("tours");
            }
        },
        [queryClient, reloadData, t]
    );

    const consolidateTourHandler = useCallback(
        async (tour: StopDraftsTour) => {
            try {
                await consolidateTour(tour);
                analytics.tourConsolidated("big-volume");
                toast.success(t("successMessage.tourConsolidated"));
                setSelectedTourIds((state) => [
                    ...new Set([...state, tour.tour_id]),
                ]);
            } catch (error) {
                toast.error(t("errorMessage.tourConsolidateError"));
            } finally {
                await reloadData("tours");
            }
        },
        [reloadData, t]
    );

    const addPauseToTourHandler = useCallback(
        async ({
            tourId,
            toLocation,
            estimatedOffloadTime,
            tourPosition,
            driverInstructions,
        }: {
            tourId: number;
            toLocation?: string;
            estimatedOffloadTime: number;
            tourPosition: number;
            driverInstructions?: string;
        }) => {
            try {
                await addPause({
                    tourId,
                    estimatedOffloadTime,
                    tourPosition,
                    toLocation: toLocation || null,
                    driverInstructions: driverInstructions || null,
                });
                toast.success(t("successMessage.addedPause"));
                analytics.addPauseToTour();
            } catch (error) {
                toast.error(t("errorMessage.addPauseError"));
            } finally {
                await reloadData("tours");
            }
        },
        [reloadData, t]
    );

    const tourColorMap = useMemo(() => {
        const newMap: KeyString<{
            primary: string;
            accent: string;
        }> = {};
        newMap[NEW_TOUR_COLUMN_ID] = tourColors[tourColors.length - 1];

        if (!tours) return newMap;

        for (let i = 0; i < tours.length; i++) {
            const tour = tours[i];

            newMap[tour.tour_id] = tourColors[i % tourColors.length];
        }

        return newMap;
    }, [tours]);

    const driverStatusMap = useMemo(() => {
        const map: KeyString<DriverStatus> = {};

        if (!drivers) return map;

        for (let i = 0; i < drivers.length; i++) {
            const driver = drivers[i];
            if (!driver.mt_driver_id) continue;

            const toursForDriver =
                tours?.filter(
                    (tour) => tour.preferred_driver_id === driver.mt_driver_id
                ) || [];

            const startedTour = toursForDriver.find((tour) =>
                tour.stops.some((stop) => stop.order?.pickup_stop_started_at)
            );

            const nextStop = startedTour ? getNextStop(startedTour) : undefined;

            let previousStop: TourStop | undefined = undefined;
            if (startedTour && nextStop) {
                const nextStopIndex = startedTour.stops.findIndex(
                    (stop) => stop.id === nextStop.id
                );

                if (nextStopIndex > 0) {
                    previousStop = startedTour.stops[nextStopIndex - 1];
                }
            }

            map[driver.mt_driver_id] = getDriverStatus(
                nextStop || undefined,
                previousStop
            );
        }
        return map;
    }, [drivers, tours]);

    const isTodaySelected = useMemo(() => {
        if (!filterDate) return false;
        const today = new Date();
        return isSameDay(filterDate, today);
    }, [filterDate]);

    useEffect(() => {
        if (!tours) return;

        setSelectedTourIds((state) =>
            state.filter((id) => tours.some((t) => t.tour_id === id))
        );
    }, [tours]);

    useEffect(() => {
        if (!stopDrafts) return;

        setSelectedStops((state) =>
            state
                .filter(({ id }) => stopDrafts.some((s) => s.id === id))
                .map((s) => {
                    //For updating the stop that is selected if it has been updated
                    const newStop = stopDrafts.find((sd) => sd.id === s.id);
                    if (!newStop) return s;
                    return newStop;
                })
        );
    }, [stopDrafts]);

    useEffect(() => {
        if (!isMarkingAll) return;

        if (filteredAndStortedStopTableData.length > MAX_MARK_ALL_STOPS) {
            setIsMarkingAll(false);
            return;
        }

        const stopsInTable = filteredAndStortedStopTableData
            .filter(
                ({ stops }) =>
                    !!stops &&
                    (stops.pickup.origin_location
                        ? !!stops.pickup.move_accepted_at
                        : true)
            )
            .flatMap(({ stops }) =>
                stops ? [stops.pickup, stops.dropoff] : []
            );

        setSelectedStops(stopsInTable);
    }, [filteredAndStortedStopTableData, isMarkingAll]);

    const amountOfInboxCards = useMemo(() => {
        return filteredAndStortedStopTableData.filter(
            ({ orderDraft, stops }) =>
                !!orderDraft ||
                (stops?.pickup.origin_location_id &&
                    !stops.pickup.move_accepted_at)
        ).length;
    }, [filteredAndStortedStopTableData]);

    const unhandledBadgeVariant = useMemo(() => {
        if (amountOfInboxCards > 10) {
            return "error";
        }
        if (amountOfInboxCards > 0) {
            return "warning";
        }
        if (filteredAndStortedStopTableData.length > 50) {
            return "error";
        }
        if (
            filteredAndStortedStopTableData.length > 25 &&
            filteredAndStortedStopTableData.length < 50
        ) {
            return "warning";
        }
        return "success";
    }, [amountOfInboxCards, filteredAndStortedStopTableData.length]);

    return (
        <div className="big-volume">
            <div className="controls">
                <WeekDaySelector value={filterDate} onChange={setFilterDate} />

                <div className="right-controls">
                    {!isEnvironment("production") ? (
                        <DotsModal
                            content={[
                                {
                                    onClick: () => createDemoStopDrafts(),
                                    title: t("activeFleet.createDemoData"),
                                    isLoading: isCreatingDemoStopDrafts,
                                },
                                {
                                    onClick: () =>
                                        createDemoBigVolumeStopDrafts(),
                                    title: t(
                                        "activeFleet.createBigVolumeDemoData"
                                    ),
                                    isLoading:
                                        isCreatingDemoBigVolumeStopDrafts,
                                },
                                isEnvironment("staging")
                                    ? {
                                          onClick: () => createTestStopDrafts(),
                                          title: t(
                                              "activeFleet.createTestData"
                                          ),
                                          isLoading: isCreatingTestStopDrafts,
                                      }
                                    : null,
                                {
                                    onClick: () =>
                                        createInteBaraPostenDemoData(),
                                    title: t(
                                        "activeFleet.createInteBaraPostenDemoData"
                                    ),
                                    isLoading: isCreatingInteBaraPostenDemoData,
                                },
                                {
                                    onClick: () => deleteAllStopDrafts(),
                                    title: t("activeFleet.deleteAllStopDrafts"),
                                    isLoading: isDeletingAllStopDrafts,
                                },
                                {
                                    onClick: () => archiveAllActiveTours(),
                                    title: t(
                                        "activeFleet.archiveAllActiveTours"
                                    ),
                                    isLoading: isArchivingAllActiveTours,
                                },
                            ]}
                        />
                    ) : null}

                    <Tabs
                        tabs={sectionSizes}
                        activeTabValue={sectionsSize}
                        onTabChange={({ value }) =>
                            setSectionsSize(
                                value as "quarter" | "half" | "full"
                            )
                        }
                    />

                    <div className="buttons">
                        <a
                            href="https://drive.google.com/file/d/1Lt-SLriK0liXNEg8C3HiOBPk0MTHg_Dg/view?usp=drive_link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                label={t("bigVolume.newUpdates")}
                                variant="primary"
                                noSubmit
                                leadingIcon={faVideoCircle}
                            />
                        </a>

                        <Button
                            variant="secondary"
                            label={t("activeFleet.updateAllData")}
                            leadingIcon={faRefresh}
                            onClick={() =>
                                reloadData(
                                    "tours",
                                    "stop-drafts",
                                    "order-drafts",
                                    "drivers",
                                    "moved-stops"
                                )
                            }
                            isLoading={isFetchingStopDrafts || isFetchingTours}
                        />

                        <BigVolumeSettingsModal
                            state={settings}
                            drivers={drivers}
                            onChange={(key, value) =>
                                dispatch(
                                    setFleetPlannerSettings({
                                        [key]: value ? 1 : 0,
                                    })
                                )
                            }
                            onChangeDrivers={(driverId, value) => {
                                dispatch(
                                    setFleetPlannerSettings({
                                        hiddenDriverIds: {
                                            ...settings?.hiddenDriverIds,
                                            [driverId]: value ? 0 : 1,
                                        },
                                    })
                                );
                            }}
                            onBulkDelete={bulkDeleteHandler}
                            isBulkDeletingStops={isBulkDeletingStops}
                            isBulkDeletingOrderDrafts={
                                isBulkDeletingOrderDrafts
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={["content", sectionsSize].join(" ")}>
                <section className="table-section">
                    <div className="controls">
                        <div className="info">
                            <p className="text-base">
                                {t("bigVolume.unhandledTitle")}
                            </p>
                            <Badge
                                variant={unhandledBadgeVariant}
                                title={filteredAndStortedStopTableData.length.toString()}
                            />
                            {amountOfInboxCards > 0 && (
                                <Badge
                                    style={{ overflow: "hidden" }}
                                    icon={faWarning}
                                    variant={unhandledBadgeVariant}
                                    title={`${amountOfInboxCards} ${t(
                                        "bigVolume.actionRequired",
                                        {
                                            count: amountOfInboxCards,
                                        }
                                    )}`}
                                    onClick={() => setOnlyShowUnresolved(true)}
                                />
                            )}
                        </div>
                        <div className="button-group">
                            <Input
                                placeholder={t(
                                    "bigVolume.searchStopPlaceholder"
                                )}
                                leadingIcon={faSearch}
                                type="text"
                                value={stopSearch}
                                onChange={setStopSearch}
                                style={{ width: "125px" }}
                            />
                            <Button
                                leadingIcon={faFileCirclePlus}
                                variant={"secondary"}
                                label={t("bigVolume.addStop")}
                                onClick={() => setIsStopPopupOpen(true)}
                                disabled={!features?.create_stops_fleet_planner}
                            />
                            <StopFilterModal
                                stops={unhandledStops.flatMap(
                                    ({ pickup, dropoff }) => [pickup, dropoff]
                                )}
                                stopTableData={stopTableData}
                                activeFilter={activeStopFilter}
                                showOnlyUnresolved={onlyShowUnresolved}
                                onShowOnlyUnresolvedChange={
                                    setOnlyShowUnresolved
                                }
                                onFilterChange={(key, value) =>
                                    setActiveStopFilter((state) => ({
                                        ...state,
                                        [key]: value,
                                    }))
                                }
                                onClearFilter={() =>
                                    setActiveStopFilter(() => ({
                                        tags: [],
                                        pickupCities: [],
                                        dropoffCities: [],
                                        pickupLatLng: [],
                                        dropoffLatLng: [],
                                    }))
                                }
                            />
                            <CockpitStopColumnsModal />
                        </div>
                    </div>
                    <StopTable
                        data={filteredAndStortedStopTableData}
                        onRowClick={({ stops, orderDraft }) => {
                            if (stops) {
                                setStopPopupData({
                                    pickup: stops.pickup,
                                    dropoff: stops.dropoff,
                                });
                            }
                            if (
                                orderDraft &&
                                features?.create_stops_fleet_planner
                            ) {
                                setOrderDraftForCreate(orderDraft);
                                setIsStopPopupOpen(true);
                            }
                        }}
                        sectionsSize={sectionsSize}
                        //Mark all
                        isMarkingAll={isMarkingAll}
                        maxMarkAllStops={MAX_MARK_ALL_STOPS}
                        onMarkingAllChange={(newCheck) => {
                            setIsMarkingAll(newCheck);
                            if (!newCheck) {
                                setSelectedStops([]);
                            }
                        }}
                        //Selection
                        selectedStops={selectedStops}
                        onSelectedStopsChange={(newChecked, stops) =>
                            setSelectedStops((state) => {
                                if (newChecked) {
                                    return [
                                        ...new Set([
                                            ...state,
                                            stops.pickup,
                                            stops.dropoff,
                                        ]),
                                    ];
                                }

                                return state.filter(
                                    (stop) =>
                                        stop !== stops.pickup &&
                                        stop !== stops.dropoff
                                );
                            })
                        }
                        //Sorting
                        activeSort={activeStopSort}
                        onSortChange={setActiveStopSort}
                    />
                </section>

                <section>
                    <BigVolumeMap
                        hoveredStopId={hoveredStopId}
                        tours={selectedTours}
                        stops={selectedStops}
                        drivers={drivers}
                        driverStatusMap={driverStatusMap}
                        showDrivers={isTodaySelected}
                        unhandledStops={filteredAndStortedStopTableData.flatMap(
                            ({ stops }) =>
                                stops ? [stops.pickup, stops.dropoff] : []
                        )}
                        tourColorMap={tourColorMap}
                        onSelectStops={({ pickup, dropoff }) =>
                            setSelectedStops((state) => [
                                ...new Set([...state, pickup, dropoff]),
                            ])
                        }
                        onSelectMultipleStops={(stops) =>
                            setSelectedStops((state) => [
                                ...new Set([
                                    ...state,
                                    ...stops.flatMap((s) => [
                                        s.pickup,
                                        s.dropoff,
                                    ]),
                                ]),
                            ])
                        }
                        activeFilter={activeStopFilter}
                        onFilterChange={(key, value) =>
                            setActiveStopFilter((state) => ({
                                ...state,
                                [key]: value,
                            }))
                        }
                        onOpenStop={(stops) => {
                            if (stops.stops?.pickup && stops.stops?.dropoff) {
                                setStopPopupData({
                                    pickup: stops.stops?.pickup,
                                    dropoff: stops.stops?.dropoff,
                                });
                            }
                        }}
                    />
                </section>

                <section className="table-section">
                    <div className="controls">
                        <div className="info">
                            <p className="text-base">
                                {t("bigVolume.toursTitle")}
                            </p>
                            <Badge
                                variant={"neutral"}
                                title={sortedTourTableData.length.toString()}
                            />
                        </div>
                        <div className="button-group">
                            <Input
                                placeholder={t(
                                    "bigVolume.searchTourPlaceholder"
                                )}
                                leadingIcon={faSearch}
                                type="text"
                                value={tourSearch}
                                onChange={setTourSearch}
                            />
                        </div>
                    </div>
                    <TourTable
                        data={sortedTourTableData}
                        sectionsSize={sectionsSize}
                        //Selection
                        selectedTourIds={selectedTourIds}
                        onSelectedTourIdsChange={(newChecked, tourId) =>
                            setSelectedTourIds((state) =>
                                newChecked
                                    ? [...state, tourId]
                                    : state.filter((id) => id !== tourId)
                            )
                        }
                        onRowClickStops={(stops) =>
                            setStopPopupData({
                                pickup: stops.pickup,
                                dropoff: stops.dropoff,
                            })
                        }
                        //Sort
                        activeSort={activeTourSort}
                        onSortChange={setActiveTourSort}
                        //Actions
                        onGroupTourClick={setGroupTourId}
                        onArchiveTourClick={archiveTourHandler}
                        onConsolidateTourClick={consolidateTourHandler}
                    />
                </section>

                <section className="drag-drop-section">
                    <ToursDragDrop
                        onHoverStopId={setHoveredStopId}
                        tours={selectedTours}
                        stops={selectedStops}
                        filterDate={filterDate}
                        onDragEnd={onDragEnd}
                        columnIdsLoading={columnIdsLoading}
                        stopIdsLoading={[
                            ...unhandledIdsLoading,
                            ...detachingIds,
                        ]}
                        tourColorMap={tourColorMap}
                        onStopCardClick={setStopPopupData}
                        onClearStops={() => setSelectedStops([])}
                        onGroupTourClick={setGroupTourId}
                        onGroupStopsClick={() =>
                            setShowGroupPopupWithSelectedStops(true)
                        }
                        onSelectTourId={(tourId) =>
                            setSelectedTourIds((state) => [
                                ...new Set([...state, tourId]),
                            ])
                        }
                        onAddPause={
                            isEnvironment("staging")
                                ? addPauseToTourHandler
                                : undefined
                        }
                        onDetachPause={(pause) => {
                            detachPauseHandler(pause);
                        }}
                    />
                </section>
            </div>

            <StopFormPopup
                showPopup={isStopPopupOpen}
                onClose={() => {
                    setIsStopPopupOpen(false);
                    setEditStops(null);
                    setOrderDraftForCreate(null);
                    setCopyStops(undefined);
                }}
                onCreated={onStopsCreated}
                onEdited={() => {
                    reloadData("stop-drafts", "tours");
                    setIsStopPopupOpen(false);
                    setEditStops(null);
                    setOrderDraftForCreate(null);
                    setCopyStops(undefined);
                }}
                onDeleted={() => {
                    reloadData("stop-drafts", "tours");
                    setIsStopPopupOpen(false);
                    setEditStops(null);
                    setOrderDraftForCreate(null);
                    setCopyStops(undefined);
                }}
                editStops={editStops}
                baseStops={copyStops}
                orderDraft={orderDraftForCreate}
            />

            {stopPopupData && (
                <StopsInfoPopup
                    showPopup={!!stopPopupData}
                    onClose={() => setStopPopupData(null)}
                    stops={stopPopupData}
                    groupedStops={stopPopupData.groupedStops}
                    onEditStops={(stops) => {
                        setEditStops(stops);
                        setStopPopupData(null);
                        setIsStopPopupOpen(true);
                    }}
                    onCopyStops={(stops) => {
                        setCopyStops(stops);
                        setStopPopupData(null);
                        setIsStopPopupOpen(true);
                    }}
                    onDetachStops={(stops) => {
                        detachStopsHandler(stops);
                        setStopPopupData(null);
                    }}
                    onAssignOutsideFleet={(stops) => {
                        setStopsForExternalMarking(stops);
                        setStopPopupData(null);
                    }}
                    onChangedLocation={(stops) => {
                        setChangeLocationStops(stops);
                        setStopPopupData(null);
                    }}
                    onDeselectStops={
                        selectedStops.some(
                            (stop) => stop.id === stopPopupData.pickup.id
                        )
                            ? (stops) => {
                                  setSelectedStops((state) =>
                                      state.filter(
                                          (stop) =>
                                              stop.id !== stops.pickup.id &&
                                              stop.id !== stops.dropoff.id
                                      )
                                  );
                                  setStopPopupData(null);
                              }
                            : undefined
                    }
                    onRespondToStopRequest={(data) => {
                        respondToStopRequestHandler(data);
                        setStopPopupData(null);
                    }}
                    onSendBackToOrginLocation={(stops) => {
                        respondToStopRequestHandler({
                            ...stops,
                            response: "reject",
                        });
                        setStopPopupData(null);
                    }}
                    onFulfillExternalStop={({ pickup }) => {
                        fulfillExternalStopHandler(pickup);
                        setStopPopupData(null);
                    }}
                    onDetachExternalStop={({ pickup }) => {
                        detachExternalStopHandler(pickup);
                        setStopPopupData(null);
                    }}
                />
            )}

            {changeLocationStops && (
                <StopChangeLocationPopup
                    showPopup={!!changeLocationStops}
                    onClose={() => setChangeLocationStops(null)}
                    stopIds={[
                        changeLocationStops.pickup.id,
                        changeLocationStops.dropoff.id,
                    ]}
                    onLocationChange={() =>
                        reloadData("stop-drafts", "moved-stops")
                    }
                />
            )}

            {stopsForExternalMarking && (
                <ExternalStopsPopup
                    showPopup={!!stopsForExternalMarking}
                    onClose={() => setStopsForExternalMarking(null)}
                    onSubmit={() => reloadData("stop-drafts")}
                    {...stopsForExternalMarking}
                    preselectedDate={dateToString(filterDate)}
                />
            )}

            {(!!groupTour || showGroupPopupWithSelectedStops) && (
                <GroupTourStopsPopup
                    showPopup={!!groupTour || showGroupPopupWithSelectedStops}
                    onClose={() => {
                        setGroupTourId(null);
                        setShowGroupPopupWithSelectedStops(false);
                    }}
                    droppableId={
                        groupTour
                            ? groupTour.tour_id.toString()
                            : NEW_TOUR_COLUMN_ID
                    }
                    onDragEnd={onDragEnd}
                    isColumnLoading={columnIdsLoading.includes(
                        groupTour?.tour_id?.toString() || NEW_TOUR_COLUMN_ID
                    )}
                    tour={groupTour || undefined}
                    onStopsGrouped={() => reloadData("tours")}
                    onStateStopsGrouped={setSelectedStops}
                    onSortClick={() =>
                        groupTour
                            ? sortTourPickupsFirst({
                                  tourId: groupTour.tour_id,
                                  stops: groupTour.stops,
                              })
                            : undefined
                    }
                    stops={groupTour?.stops || selectedStops || []}
                />
            )}
        </div>
    );
}

export default BigVolume;
